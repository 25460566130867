import { Fragment, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'

import toolService from 'services/tool.service'

function ToolPassengerMembership() {
  const [validated, setValidated] = useState(false)
  const [passengers, setPassengers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [dob, setDob] = useState<string>('')

  /**
   * Searches for passenger membership details.
   */
  const checkMembership = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setValidated(true)
    const formEl = event.currentTarget

    if (!formEl.checkValidity()) {
      return
    }

    setPassengers([])
    setLoading(true)

    const { data }: any = await toolService.checkPassengerMembership({
      firstname: firstname,
      lastname: lastname,
      dob,
    })

    setPassengers(data?.ParticipantData || [])
    setLoading(false)
  }

  /**
   * Clears the form fields.
   */
  const clear = () => {
    setValidated(false)
    setFirstname('')
    setLastname('')
    setDob('')
    setPassengers([])
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={checkMembership}>
        <div className="row">
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">
                Firstname
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={firstname}
                onInput={(e) => setFirstname(e.currentTarget.value)}
                placeholder="Firstname"
              />
            </Form.Group>
          </div>
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">
                Lastname
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={lastname}
                onInput={(e) => setLastname(e.currentTarget.value)}
                placeholder="Lastname"
              />
            </Form.Group>
          </div>
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">DOB</Form.Label>
              <Form.Control
                type="date"
                required
                value={dob}
                onInput={(e) => setDob(e.currentTarget.value)}
                placeholder="Date of Birth"
              />
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button type="button" variant="outline-secondary" onClick={clear}>
            Clear
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </Form>
      <div>
        <Form.Label>Results</Form.Label>
        <div className="fs-10 text-gray">
          {loading ? 'Finding membership details...' : ''}
        </div>
        <div>
          {!loading &&
            (passengers.length ? (
              passengers.map((pax: any, index: number) => (
                <Fragment key={`pax-${dob}-${index}`}>
                  <Form.Label className="fw-semibold">Address</Form.Label>
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Address1</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">City</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">ZipCode</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">TelephoneNo</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Cellular</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">EmailId</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fs-10">
                      <tr>
                        <td>{pax.ParticipantAddress?.Address1}</td>
                        <td>{pax.ParticipantAddress?.City}</td>
                        <td>{pax.ParticipantAddress?.ZipCode}</td>
                        <td>{pax.ParticipantAddress?.TelephoneNo}</td>
                        <td>{pax.ParticipantAddress?.Cellular}</td>
                        <td>{pax.ParticipantAddress?.EmailId}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Form.Label className="fw-semibold mt-3">
                    Frequent Traveller Info
                  </Form.Label>
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Number</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Club</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Points</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fs-10">
                      <tr>
                        <td>
                          {pax.FrequentTravellerInfo?.FrequentTravelerNumber}
                        </td>
                        <td>{pax.FrequentTravellerInfo?.Club}</td>
                        <td>{pax.FrequentTravellerInfo?.Score}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Form.Label className="fw-semibold mt-3">
                    Passport Data
                  </Form.Label>
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Number</h6>
                        </th>
                        <th scope="col">
                          <h6 className="text-700 fw-semibold">Expire Date</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fs-10">
                      <tr>
                        <td>{pax.PassportData?.PassportNo}</td>
                        <td>{pax.PassportData?.PassportExpireDate}</td>
                      </tr>
                    </tbody>
                  </Table>
                  {index < passengers.length - 1 && (
                    <hr className="border-primary mt-4 mb-5" />
                  )}
                </Fragment>
              ))
            ) : (
              <div className="fs-10 text-gray">No data available.</div>
            ))}
        </div>
      </div>
    </>
  )
}

export default ToolPassengerMembership
