import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/itineraries`

/**
 * Fetches the itinerary segments for a given cruise ID.
 */
export const getSegmentsByCruiseId = async (cruiseId: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/cruise/${cruiseId}/segments`,
      method: 'GET',
    },
  })

/**
 * Refreshes the itinerary segments for a given cruise ID.
 */
export const refreshSegmentsByCruiseId = async (cruiseId: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/cruise/${cruiseId}/segments/refresh`,
      method: 'GET',
    },
  })

/**
 * Gets the cruises for a given itinerary code.
 */
export const getCruisesByItinCode = async (itinCode: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${itinCode}/cruises`,
      method: 'GET',
    },
  })

const itineraryService = {
  getCruisesByItinCode,
  getSegmentsByCruiseId,
  refreshSegmentsByCruiseId,
}

export default itineraryService
