import dayjs from 'dayjs'
import useBooking from 'hooks/useBooking'
import useUtils from 'hooks/useUtils'
import { useEffect, useMemo, useState } from 'react'
import { Row, Col, Table, Spinner } from 'react-bootstrap'
import itineraryService from 'services/itinerary.service'

type Props = {
  itinCode: string;
  cruiseId: string;
};

function ItineraryContent({ cruiseId, itinCode }: Props) {
  const { itineraryMapFromCode } = useBooking()
  const { formatHundredthHours } = useUtils()

  const [itinerarySegments, setItinerarySegments] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  // Returns the itinerary map image URL
  const itineraryMap = useMemo(
    () => itineraryMapFromCode(itinCode),
    [itinCode]
  )

  // If the modal's visibility changes...
  useEffect(() => {
    fetchItinerarySegments()
  }, [])

  /**
   * Fetches the itinerary segments for a given cruise ID.
   */
  const fetchItinerarySegments = async () => {
    setLoading(true)

    const { data }: any = await itineraryService.getSegmentsByCruiseId(
      cruiseId
    )

    setItinerarySegments(data)
    setLoading(false)
  }

  return (
    <Row className="g-3">
      <Col xxl={3} xl={4} className="order-xl-0">
        {itinCode ? (
          <div className="d-flex justify-content-center mt-xl-5 mb-4">
            <a href={itineraryMap} target="_blank" rel="noreferrer">
              <img
                src={itineraryMap}
                className="img-fluid"
                alt="Itinerary Map"
              />
            </a>
          </div>
        ) : (
          <div className="alert alert-warning my-1">
            <p className="text-muted m-0 fs-10">
              Then itinerary code for the cruise is either provided or not found from the
              database.
            </p>
          </div>
        )}
      </Col>
      <Col xxl={9} xl={8}>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700 fw-bold">Day</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-bold">Date</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-bold">Port</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-bold">Arrival</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-bold">Departure</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : !itinerarySegments.length ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center fs-10 text-800 text-nowrap"
                >
                  No itinerary segments.
                </td>
              </tr>
            ) : (
              itinerarySegments.map((segment) => (
                <tr key={`segment-${segment.id}`}>
                  <td className="fs-10 text-800 fw-bold text-nowrap">
                    {segment.itinerary_weekday}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {dayjs(segment.itinerary_date).format('DD/MM/YYYY')}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {segment.itinerary_port_name}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {formatHundredthHours(segment.arrival_time)}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {formatHundredthHours(segment.departure_time)}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ItineraryContent
