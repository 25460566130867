import { Button, Modal } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import ItineraryContent from 'modules/simple-crm/components/itinerary/ItineraryContent'

interface ItineraryModalProps {
  booking: any;
  show: boolean;
  onClose: Function;
}

const ItineraryModal = ({ booking, onClose, show }: ItineraryModalProps) => {
  /**
   * Closes the modal.
   */
  const closeModal = () => {
    onClose()
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false} size="xl">
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Full Itinerary
        </Flex>
        {show && (
          <ItineraryContent
            cruiseId={booking.cruise_id}
            itinCode={booking.itinerary_code}
          />
        )}
        <div className="d-flex justify-content-end">
          <Button variant="falcon-default" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ItineraryModal
